/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style";

// Replace above style with this SCSS file to enable dark mode styles
//@import "./assets/sass/style.dark";

@import "./assets/sass/style.angular.scss";

@import "~@ng-select/ng-select/themes/default.theme.css";
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';

.container-xxl {
    max-width: 100%;
}

button.smart-button:active {
    color: #333 !important;
    border-color: #ebebeb !important;
    background-color: #ebebeb !important;
}

button.smart-button[type]:not([disabled]):active,
button.smart-button[type]:not([disabled]):active,
button.smart-button[type]:not([disabled]):active,
button.smart-button:active {
    border-color: #ebebeb !important;
}

.ng-select.ng-select-opened .ng-select-container {
    z-index: 1 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    z-index: 0 !important;
}

.ng-dropdown-panel {
    z-index: 3 !important;
}

app-login.ng-star-inserted,
app-registration.ng-star-inserted,
app-forgot-password.ng-star-inserted,
app-resetpassword.ng-star-inserted {
    background-color: #E4E6EF;
}

.dateformat {
    position: relative;
    height: 36px;
}

.dateformat:before {
    position: absolute;
    top: 3px;
    left: 3px;
    content: attr(data-date);
    display: inline-block;
    color: black;
}

.dateformat::-webkit-datetime-edit,
.dateformat::-webkit-inner-spin-button,
.dateformat::-webkit-clear-button {
    display: none;
}

.dateformat::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 3px;
    right: 0;
    color: black;
    opacity: 1;
}

:root {
    --smart-ui-state-border-active: #e2e0e0 !important;
}

.pac-container {
    z-index: 9999 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    flex-wrap: nowrap !important;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    overflow-x: auto;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.smart-scheduler {
    width: 100%;
    height: 100%;
    --smart-scheduler-event-size: 40px;
}

@media (max-width: 750px) {
    .smart-scheduler {
        width: 100%;
    }
}

:root {
    --smart-ui-state-hover: null !important;
}
.smart-scheduler-tooltip .smart-scheduler-event-button{
    display: none !important;
  }

  .smart-header {
    flex-grow: 1 !important;
    text-align: center !important;
  }

  smart-grid .smart-data-view-customize-button{
    display:none !important
  }

.ng-select.ng-select-single .ng-select-container,.height-28px {
 height: 28px !important;
}
.ng-select .ng-select-container { 
min-height: 28px !important;
}