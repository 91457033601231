//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "layout/layout";
@import "./core/layout/docs/layout";
@import 'smart-webcomponents-angular/source/styles/smart.default.css';

// .text-primary {
//     color: #66a3bb !important;
// }

.background-primary {
    // background-color: #009EF7 !important;
    /* background-color: #98c1d9 !important; */
    background-color: #4680ff !important;
}

.text-secondary {
    /* color: #3d5a80 !important; */
    color: #133c70 !important;
}

.text-primary {
    /* color: #3d5a80 !important; */
    color: #4680ff !important;
}

.text-major {
    color: #66a3bb;
}

.background-secondary {
    /* background-color: #3d5a80 !important; */
    // background-color: #133c70 !important;
    // background-color: #009EF7 !important;
    background-color: #4680ff !important;
}

.text-success {
    /* color: #E0FBE1 !important; */
    color: #50ae50 !important;
}

.background-success {
    /* background-color: #E0FBE1 !important; */
    background-color: #50ae50 !important;
}

.text-danger {
    color: #ee6c4d !important;
}

.background-danger {
    background-color: #ee6c4d !important;
}

.text-info {
    /* color: #293241 !important; */
    color: #8d8d8d !important;
}

.background-info {
    /* background-color: #293241 !important; */
    background-color: #8d8d8d !important;
}

.fw-bold {
    font-weight: 600 !important;
}

.fs-4 {
    font-size: 1.25rem !important;
}

.fw-semibold {
    font-weight: 500 !important;
}

@media (min-width: 992px) {
    .aside-enabled.aside-fixed.header-fixed .header {
        height: 41px !important;
    }

    .header-fixed.toolbar-fixed .wrapper {
        padding-top: calc(50px);
        padding-bottom: calc(50px) !important;
    }
}

.aside-dark .menu .menu-item .menu-link.active .menu-icon,
.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon,
.aside-dark .menu .menu-item .menu-link.active .menu-icon i {
    color: #ffffff !important;
}

.aside-dark .menu .menu-item .menu-link.active .menu-title {
    color: #ffffff !important;
}

.aside-dark .menu .menu-item .menu-link .menu-icon,
.aside-dark .menu .menu-item .menu-link .menu-icon .svg-icon,
.aside-dark .menu .menu-item .menu-link .menu-icon i {
    margin-left: 13px;
}

.aside-dark .menu .menu-item .menu-link .menu-title {
    margin-left: 10px;
}

@media (min-width: 992px) {

    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl {
        padding: 0 10px;
    }
}

.menu-state-primary .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here),
.menu-state-primary .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {
    transition: color 0.2s ease, background-color 0.2s ease;
    color: #4680ff !important;
}

.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item.show .nav-link,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #4680ff !important;
    transition: border-width 0.6s linear;
}

.aside-dark .menu .menu-item .menu-link.active {
    transition: color 0.2s ease, background-color 0.2s ease;
    background-color: #4680ff;
    color: #ffffff;
    box-shadow: 0 10px 5px -8px #0006;
}

.aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-title,
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-title {

    color: #4680ff !important;
}

.footer {
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
}

@media(max-width:995px) {
    .footer {
        display: none !important;
    }
}

.btn-check:checked+.btn.btn-primary,
.btn-check:active+.btn.btn-primary,
.btn.btn-primary:focus:not(.btn-active),
.btn.btn-primary:hover:not(.btn-active),
.btn.btn-primary:active:not(.btn-active),
.btn.btn-primary.active,
.btn.btn-primary.show,
.show>.btn.btn-primary {
    background-color: #4680ff !important;
}


.btn.btn-primary {
    background: #4680ff !important;
}

.fw-100 {
    font-weight: 100;
}

.swal2-styled.swal2-confirm:focus,
.swal2-styled.swal2-default-outline:focus {
    box-shadow: none !important;
}

button.smart-button {
    text-transform: capitalize !important;
}

.smart-window .smart-header-section>.smart-buttons-container button:first-of-type { 
    display: none !important;
}
 .smart-maximize-button{
    display: none !important;
}

smart-scheduler .smart-scheduler-event-content[single-line] {
    grid-template-rows: 130% !important; 
}
.smart-header-section{
    background-color: rgb(0,92,153) !important;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.smart-scheduler {
    width: 100%;
    height: 100%;
    --smart-scheduler-event-size: 40px;
}

@media (max-width: 750px) {
    .smart-scheduler {
        width: 100%;
    }
}

:root {
    --smart-ui-state-hover: null !important;
}

// .smart-header-section{
//     background-color: #4680ff !important;
//     color: #ffffff !important;
// }